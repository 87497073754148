import React from "react";
import ButtonSubmit from "../../components/Button/ButtonSubmit";

export const renderFooterRefunds = (
  orderDetail,
  loadingButton,
  handleSubmitRefund,
  confirmNotRefund = () => {},
  showModalReafyToShip = () => {}
) => {
  return [
    <div
      style={{ display: "flex", flexDirection: "row" }}
      key={orderDetail?.id}
    >
      <ButtonSubmit
        onClick={showModalReafyToShip}
        loadingButton={loadingButton}
        title="XÁC NHẬN KHÔNG HOÀN TIỀN"
        key={9}
        color="#C21E39"
      />
      <ButtonSubmit
        onClick={confirmNotRefund}
        loadingButton={loadingButton}
        title="XÁC NHẬN HOÀN TIỀN"
        key={8}
        color="#5D2AF5"
      />
    </div>,
  ];
};
